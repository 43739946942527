@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

.data-echart {
  width: 792px;
  background: #eeeeee;
  border-radius: 4px;
  border: 1px solid #c5c0e0;
  display: flex;
  position: relative;
}
.el-icon-refresh {
  position: absolute;
  right: 30px;
  top: 8px;
  cursor: pointer !important;
  transform: scale(1.2);
  z-index: 1000;
}
