@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

.filter-warp {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  flex-wrap: wrap;
  width: 100%;
  .w-120 {
    width: 120px;
  }
  .w-100 {
    width: 100px;
  }
  .time-label {
    height: 100%;
    line-height: 28px;
  }
}
