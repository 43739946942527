@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

::v-deep {
  .el-dialog__body {
    padding-top: 0;
  }
}
.ignore-title {
  background: rgba(127, 118, 189, 1);
  color: #fff;
  padding: 5px 100px;
  border-radius: 4px;
}

.title {
  background: #eceafa;
}

.table-header {
  box-sizing: border-box;
  padding-left: 50px;
  color: #999999;
  font-size: 14px;
  text-align: center;
}
.item {
  color: rgba(51, 51, 51, 1);
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  border-radius: 4px;
  margin-top: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  .item-index {
    margin-left: 10px;
    min-width: 18px;
    height: 18px;
    border-radius: 4px;
    border: 2px solid rgba(197, 192, 224, 1);
    color: rgba(197, 192, 224, 1);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  }
}
.del-btn {
  width: 48px;
  height: 20px;
  text-align: center;
  color: #f394ac;
  line-height: 20px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #f394ac;
}
.other {
  font-size: 14px;
  width: 54px;
  height: 20px;
  text-align: center;
  color: #fff;
  line-height: 20px;
  border-radius: 4px;
}
.left-wrap {
  width: 40%;
  padding-right: 10px;
  border-right: 1px solid #c5c0e0;
  .title {
    display: inline-block;
    padding: 3px 16px;
    margin-bottom: 8px;
    border-radius: 3px;
  }
}
.left-list {
  height: 300px;
  overflow-y: auto;
  padding-right: 10px;
  margin: 10px 0;
  .add-btn {
    background-image: url("../../../../assets/add_btn_n.png");
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    width: 18px;
    height: 24px;
  }
}
.shenglue {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.title1 {
  width: 100%;
  height: 24px;
  font-size: 18px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  color: #333333;
  line-height: 24px;
  text-align: center;
  background: #eceafa;
  border-radius: 4px;
  margin-bottom: 8px;
}
